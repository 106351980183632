@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  background-color: theme("colors.warmGray.200");
  color: theme("colors.warmGray.900");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  @font-face {
    font-family: "LinotypeOptima";
    font-weight: normal;
    font-style: normal;
    src: url("./static/fonts/Linotype - OptimaLTStd.otf") format("opentype");
  }

  @font-face {
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: normal;
    src: url("./static/fonts/PxGrotesk-Light.otf") format("opentype");
  }

  @font-face {
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: italic;
    src: url("./static/fonts/PxGrotesk-LightIta.otf") format("opentype");
  }

  @font-face {
    font-family: "PxGrotesk";
    font-weight: normal;
    font-style: normal;
    src: url("./static/fonts/PxGrotesk-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: "PxGrotesk";
    font-weight: normal;
    font-style: italic;
    src: url("./static/fonts/PxGrotesk-RegularIta.otf") format("opentype");
  }

  @font-face {
    font-family: "PxGrotesk";
    font-weight: bold;
    font-style: normal;
    src: url("./static/fonts/PxGrotesk-Bold.otf") format("opentype");
  }

  @font-face {
    font-family: "PxGrotesk";
    font-weight: bold;
    font-style: italic;
    src: url("./static/fonts/PxGrotesk-Bold.otf") format("opentype");
  }
}

.hero-section-label {
  @apply text-xs block uppercase font-bold tracking-widest text-alto;
}
